import axios from 'axios'
import { ADMIN, ANALYST } from '@/store/const/userRoles'

export default {
  state: {
    loggedUserId: null,
    loggedUserRole: null,
    loggedUserFio: null,
  },
  getters: {
    getLoggedUserId: state => Number(state.loggedUserId),
    getLoggedUserRole: state => state.loggedUserRole,
    getLoggedUserFio: state => state.loggedUserFio,
    isAdmin: state => state.loggedUserRole === ADMIN,
    isAnalyst: state => state.loggedUserRole === ANALYST,
  },
  mutations: {
    SET_LOGGED_USER_ID(state, payload) {
      state.loggedUserId = payload
    },
    SET_LOGGED_USER_ROLE(state, payload) {
      state.loggedUserRole = payload
    },
    SET_LOGGED_USER_FIO(state, payload) {
      state.loggedUserFio = payload
    },
  },
  actions: {
    setLoggedUserId(context, id) {
      this.commit('SET_LOGGED_USER_ID', id)
    },
    setLoggedUserRole(context, role) {
      this.commit('SET_LOGGED_USER_ROLE', role)
    },
    setLoggedUserFio(context, fio) {
      this.commit('SET_LOGGED_USER_FIO', fio)
    },
    async loadLoggedUser() {
      try {
        let res = await axios.get('/api/auth/authenticated?getUser')
        if (!res.data?.authenticated || !res.data?.user)
          throw new Error('User not authenticated')
        const { user } = res.data
        this.commit('SET_LOGGED_USER_ID', user.id)
        this.commit('SET_LOGGED_USER_ROLE', user.role)
        this.commit('SET_LOGGED_USER_FIO', user.fio)
      } catch {
        this.commit('SET_LOGGED_USER_FIO', null)
        this.commit('SET_LOGGED_USER_ROLE', null)
        this.commit('SET_LOGGED_USER_ID', null)
      }
    },
    async logout() {
      try {
        await axios.get('/api/auth/logout')
      } finally {
        // в любом случае сбрасывем данные поле выхода
        this.commit('SET_LOGGED_USER_FIO', null)
        this.commit('SET_LOGGED_USER_ROLE', null)
        this.commit('SET_LOGGED_USER_ID', null)
      }
    },
  },
}
