import axios from 'axios'
import { fileActions, addFile } from '@/store/const/fileActions'

export default {
  state: {
    isLoading: false,
    clinRecs: [],
    currentClinRec: {},
  },
  getters: {
    getClinRecs: state => state.clinRecs,
    getCurrentClinRec: state => state.currentClinRec,
    getCurrentClinRecProfile: state => state.currentClinRec.profile,
    isClinRecLoading: state => state.isLoading,
  },
  mutations: {
    SET_CLINRECS(state, payload) {
      if (Array.isArray(payload)) {
        state.clinRecs = payload
      } else state.clinRecs = []
    },
    SET_CURRENT_CLINREC(state, payload) {
      state.currentClinRec = payload
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload
    },
  },
  actions: {
    // Зарузка нозологий
    async loadClinRecs({ commit }) {
      commit('LOADING', true)
      try {
        let { data } = await axios.get('/api/cr?file')

        // fix file.name = file.original_name
        if (Array.isArray(data))
          data.forEach(cr => {
            if (cr.files?.length) {
              cr.files.forEach(file => {
                file.name = file.original_name
              })
            }
          })
        commit('SET_CLINRECS', data)
        return data
      } catch (error) {
        commit('SET_CLINRECS', [])
        commit('PUSH_ERROR', { head: 'Зарузка нозологий', error })
        throw error
      } finally {
        commit('LOADING', false)
      }
    },
    // Зарузка списка файлов для нозологий
    async loadClinRecFilesById({ commit }, id) {
      commit('LOADING', true)
      try {
        let { data } = await axios.get(`/api/cr/${id}/file`)
        commit('SET_FILES', data)
        return data
      } catch (error) {
        commit('SET_FILES', [])
        commit('PUSH_ERROR', {
          head: 'Зарузка списка файлов для нозологий',
          error,
        })
        throw error
      } finally {
        commit('LOADING', false)
      }
    },
    // Зарузка нозологии
    async loadClinRecById({ commit }, id) {
      commit('LOADING', true)
      commit('SET_IS_LOADING', true)
      try {
        let { data } = await axios.get(`/api/cr/${id}?file`)
        if (Array.isArray(data.inquirer?.files)) {
          data.inquirer.files.forEach(file => {
            file.name = file.original_name
          })
        }
        if (data.owner?.fio) {
          data.analyst = data.owner.fio
        }
        commit('SET_CURRENT_CLINREC', data)
        return data
      } catch (error) {
        commit('SET_FILES', [])
        commit('PUSH_ERROR', {
          head: 'Зарузка нозологии',
          error,
        })
        throw error
      } finally {
        commit('SET_IS_LOADING', false)
        commit('LOADING', false)
      }
    },
    // Добавление нозологии
    async addClinRec({ commit, dispatch }, params) {
      commit('UPDATING', true)
      try {
        let { data } = await axios.post(`/api/cr`, params)
        await dispatch('addClinRegFiles', { id: data.id, files: params.files })
        // dispatch('loadClinRecs') // loadClinRecs уже есть в addClinRegFiles
        return data
      } catch (error) {
        commit('PUSH_ERROR', {
          head: 'Добавление нозологии',
          error,
        })
        throw error
      } finally {
        commit('UPDATING', false)
      }
    },
    // Редактирование нозологии
    async editClinRec({ commit, dispatch }, params) {
      commit('UPDATING', true)
      try {
        await axios.patch(`/api/cr/${params.id}`, params)
        await dispatch('editClinRegFiles', params)

        if (params.stagedFiles?.length) {
          await dispatch('resetAnswerStatus', params.id)
        } else {
          // loadClinRecs уже есть в resetAnswerStatus
          dispatch('loadClinRecs')
        }
        return params
      } catch (error) {
        commit('PUSH_ERROR', {
          head: 'Редактирование нозологии',
          error,
        })
        throw error
      } finally {
        commit('UPDATING', false)
      }
    },
    // Сброс статуса ответа
    async resetAnswerStatus({ commit, dispatch }, id) {
      commit('UPDATING', true)
      try {
        await axios.put(`/api/cr/${id}/inquirer/reset-was-sent`)
        dispatch('loadClinRecs')
      } catch (error) {
        commit('PUSH_ERROR', { error })
        throw error
      } finally {
        commit('UPDATING', false)
      }
    },
    // Удаление нозологии
    async deleteClinRec({ commit, dispatch }, id) {
      commit('UPDATING', true)
      try {
        await axios.delete(`/api/cr/${id}`)
        dispatch('loadClinRecs')
      } catch (error) {
        commit('PUSH_ERROR', { head: 'Удаление нозологии', error })
        throw error
      } finally {
        commit('UPDATING', false)
      }
    },
    // Добавление файлов к нозологии
    async addClinRegFiles({ commit, dispatch }, params) {
      commit('UPDATING', true)
      try {
        const uploadFilesPromises = []

        for (let file of params.files) {
          const formdata = new FormData()
          formdata.append('file', file)

          uploadFilesPromises.push(
            axios.post(`/api/cr/${params.id}/file`, formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
          )
        }
        await Promise.all(uploadFilesPromises)
        dispatch('loadClinRecs')
      } catch (error) {
        commit('PUSH_ERROR', { head: 'Добавление файлов к нозологии', error })
        throw error
      } finally {
        commit('UPDATING', false)
        // dispatch('loadClinRecs') // может тут ??
      }
    },
    // Редактирование файлов нозологии
    async editClinRegFiles({ commit, dispatch }, params) {
      commit('UPDATING', true)
      try {
        const uploadFilesPromises = []
        for (let stagedFile of params.stagedFiles) {
          const { file, fileAction } = stagedFile
          if (fileAction === fileActions[addFile]) {
            const formdata = new FormData()
            formdata.append('file', file)

            uploadFilesPromises.push(
              axios.post(`/api/cr/${params.id}/file`, formdata, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
            )
          } else {
            uploadFilesPromises.push(axios.delete(`/api/file/${file.id}`))
          }
        }
        await Promise.all(uploadFilesPromises)
        dispatch('loadClinRecs')
      } catch (error) {
        commit('PUSH_ERROR', { head: 'Редактирование файлов нозологии', error })
        throw error
      } finally {
        commit('UPDATING', false)
        // dispatch('loadClinRecs') // может тут ??
      }
    },
  },
}
