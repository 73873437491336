export const ADMIN = 'admin'
export const ANALYST = 'analyst'
export const EXPERT = 'expert'
export const OBSERVER = 'observer'

export const USER_ROLES = [
  {
    value: ADMIN,
    roleName: 'Администратор',
    dativRoleName: 'администратора',
    canAnswer: false,
    showClinRecAmount: false,
    showOwner: false,
  },
  {
    value: ANALYST,
    roleName: 'Аналитик',
    dativRoleName: 'аналитика',
    canAnswer: false,
    showClinRecAmount: true,
    showOwner: false,
  },
  {
    value: EXPERT,
    roleName: 'Эксперт',
    dativRoleName: 'эксперта',
    canAnswer: true,
    showClinRecAmount: true,
    showOwner: true,
  },
  {
    value: OBSERVER,
    roleName: 'Наблюдатель',
    dativRoleName: 'наблюдателя',
    canAnswer: false,
    showClinRecAmount: false,
    showOwner: false,
  },
]
