import axios from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { EXPERT } from '@/store/const/userRoles'

Vue.use(VueRouter)

const routes = [
  {
    path: '/expert',
    name: 'ExpertHome',
    component: () => import('@/views/ExpertHome.vue'),
  },
  {
    path: '/question/:clinRecId',
    name: 'Question',
    component: () => import('@/views/Question.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/Admin.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPassword.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

async function getUser() {
  try {
    const { data } = await axios.get('/api/auth/authenticated?getUser')
    if (data.user === undefined) data.user.role = null
    return data
  } catch (err) {
    return { authenticated: false, user: { role: null } }
  }
}

function redirectToHomePageByRole(role) {
  if (role === EXPERT) router.push('/expert')
  else router.push('/admin')
}

router.beforeEach(async (to, from, next) => {
  const {
    authenticated,
    user: { role },
  } = await getUser()
  const page = to.name

  if (page === 'ResetPassword') {
    next()
  } else if (page !== 'Login' && !authenticated) {
    next({ name: 'Login' })
  } else if ((page === 'Login' || !page) && authenticated) {
    redirectToHomePageByRole(role)
  } else if (page === 'Admin' && role === EXPERT) {
    next({ name: 'ExpertHome' })
  } else if (
    (page === 'ExpertHome' || page === 'Question') &&
    role !== EXPERT
  ) {
    next({ name: 'Admin' })
  } else {
    next()
  }
})

export default router
