import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

// toaster
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
const toastOptions = {}
Vue.use(Toast, toastOptions)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

// одна или две цифры месяца + число + две цифра номера сборки за сегодня
console.log('front: 11172')
