import axios from 'axios'

export default {
  state: {
    files: [],
  },
  getters: {
    getFiles: state => state.files,
  },
  mutations: {
    SET_FILES(state, payload) {
      state.files = payload
    },
  },
  actions: {
    async loadFiles({ commit }) {
      commit('LOADING', true)
      try {
        let { data } = await axios.get('/api/file')
        data.forEach(file => {
          file.name = file.original_name
        })
        commit('SET_FILES', data)
        return data
      } catch (error) {
        commit('SET_FILES', [])
        commit('PUSH_ERROR', { head: 'Загрузка файлов', error })
        throw error
      } finally {
        commit('LOADING', false)
      }
    },
    async deleteFile({ commit, dispatch }, id) {
      commit('UPDATING', true)
      try {
        await axios.delete(`/api/file/${id}`)
        return dispatch('loadFiles')
      } catch (error) {
        commit('PUSH_ERROR', { head: 'Удаление файла', error })
        throw error
      } finally {
        commit('UPDATING', false)
      }
    },
  },
}
