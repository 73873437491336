export default {
  state: {
    filterText: '',
    filterProfile: null,
  },
  getters: {
    getFilterText: state => state.filterText,
    getFilterProfile: state => state.filterProfile,
  },
  mutations: {
    SET_FILTER_TEXT(state, payload) {
      state.filterText = payload
    },
    SET_FILTER_PROFILE(state, payload) {
      state.filterProfile = payload
    },
  },
  actions: {
    setFilterText(context, text) {
      this.commit('SET_FILTER_TEXT', text)
    },
    setFilterProfile(context, val) {
      this.commit('SET_FILTER_PROFILE', val)
    },
    resetFilter() {
      this.commit('SET_FILTER_TEXT', '')
      this.commit('SET_FILTER_PROFILE', null)
    },
  },
}
