const axios = require('axios').default
import { ANALYST } from '@/store/const/userRoles'

export default {
  state: {
    users: [],
  },
  getters: {
    getUsers: state => state.users,
    getAnalyst: state => state.users.filter(u => u.role === ANALYST),
  },
  mutations: {
    SET_USERS(state, payload) {
      state.users = payload
    },
  },
  actions: {
    async loadUsers({ commit }) {
      try {
        let { data } = await axios.get(`/api/user`)
        commit('SET_USERS', data)
        return data
      } catch (error) {
        commit('SET_USERS', [])
        commit('PUSH_ERROR', { head: 'Загрузка пользователя', error })
        throw error
      }
    },
    async addUser({ commit, dispatch }, params) {
      try {
        await axios.post('/api/user', params)
        return dispatch('loadUsers')
      } catch (error) {
        commit('PUSH_ERROR', { head: 'Добавление пользователя', error })
        throw error
      }
    },
    async editUser({ commit, dispatch }, params) {
      try {
        await axios.patch(`/api/user/${params.id}`, params)
        return dispatch('loadUsers')
      } catch (error) {
        commit('PUSH_ERROR', { head: 'Редактирование пользователя', error })
        throw error
      }
    },
    async deleteUser({ commit, dispatch }, id) {
      try {
        await axios.delete(`/api/user/${id}`)
        return dispatch('loadUsers')
      } catch (error) {
        commit('PUSH_ERROR', { head: 'Удаление пользователя', error })
        throw error
      }
    },
  },
}
