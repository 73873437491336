import axios from 'axios'
import { fileActions, addFile } from '@/store/const/fileActions'

function createEmptyQuestionnarie(length) {
  const emptyQ = []
  for (let i = 0; i < length; i++) {
    emptyQ.push({
      id: i,
      answerId: null,
      comment: null,
      filesIds: [],
    })
  }
  return emptyQ
}

export default {
  state: {
    questionnaires: [],
  },
  getters: {
    getQuestionnaires: state => state.questionnaires,
  },
  mutations: {
    SET_QUESTIONNAIRES(state, payload) {
      state.questionnaires = payload
    },
  },
  actions: {
    async loadQuestionnaires({ commit }) {
      commit('LOADING', true)
      try {
        let { data } = await axios.get('/api/inquirer')
        commit('SET_QUESTIONNAIRES', data)
        return data
      } catch (error) {
        commit('SET_QUESTIONNAIRES', [])
        commit('PUSH_ERROR', { head: 'Загрузка опросника', error })
        throw error
      } finally {
        commit('LOADING', false)
      }
    },
    async addQuestionnaire({ commit, dispatch }, params) {
      commit('UPDATING', true)
      try {
        await axios.post('/api/inquirer', {
          cr_id: params.cr_id,
          user_id: params.user_id,
          data: createEmptyQuestionnarie(params.length),
          was_sent: null,
        })
        return dispatch('loadQuestionnaires')
      } catch (error) {
        commit('PUSH_ERROR', { head: 'Добавление опросника', error })
        throw error
      } finally {
        commit('UPDATING', false)
      }
    },
    async editQuestionnaire({ commit, dispatch }, params) {
      commit('UPDATING', true)
      try {
        await axios.patch(`/api/inquirer/${params.id}`, params)
        if (params?.clinRecId) {
          await dispatch('loadClinRecById', params.clinRecId)
        }
        return dispatch('loadQuestionnaires')
      } catch (error) {
        commit('PUSH_ERROR', { head: 'Редактирование опросника', error })
        throw error
      } finally {
        commit('UPDATING', false)
      }
    },
    async deleteQuestionnaire({ commit, dispatch }, id) {
      commit('UPDATING', true)
      try {
        await axios.delete(`/api/inquirer/${id}`)
        return dispatch('loadQuestionnaires')
      } catch (error) {
        commit('PUSH_ERROR', { head: 'Загрузка файлов', error })
        throw error
      } finally {
        commit('UPDATING', false)
      }
    },
    async updateQuestionFiles(
      { commit, dispatch },
      { inquirerId, questionId, files, clinRecId }
    ) {
      // грузим и удаляем файлы
      const uploadFilesPromises = []
      const deleteFilesPromises = []
      const deleteFilesIds = []
      commit('UPDATING', true)
      try {
        files.forEach(file => {
          if (file.fileAction === fileActions[addFile]) {
            const formdata = new FormData()
            formdata.append('file', file.file)
            uploadFilesPromises.push(
              axios.post(`/api/inquirer/${inquirerId}/file`, formdata)
            )
          } else {
            deleteFilesIds.push(file.file.id)
            deleteFilesPromises.push(axios.delete(`/api/file/${file.file.id}`))
          }
        })

        // Затем. Получаем id загруженных файлов
        const uploadFilesIds = (await Promise.all(uploadFilesPromises)).map(
          f => f.data.id
        )

        // Получаем текущие ответы
        const answers = (await axios.get(`/api/inquirer/${inquirerId}`)).data
          .data

        // Собираем новый массив с id файлов прикрепленных к этом ответу
        const filesIdsForUpdate = [
          ...answers[questionId].filesIds,
          ...uploadFilesIds,
        ]
        deleteFilesIds.forEach(delId => {
          const index = filesIdsForUpdate.findIndex(fid => fid === delId)
          if (index !== -1) {
            filesIdsForUpdate.splice(index, 1)
          }
        })

        // обновляем данные о файлах в анкете и загружаем все
        answers[questionId].filesIds = filesIdsForUpdate.slice()
        await axios.patch(`/api/inquirer/${inquirerId}`, {
          data: answers,
        })
        if (clinRecId) {
          await dispatch('loadClinRecById', clinRecId)
        }
        await dispatch('loadFiles')
        return dispatch('loadQuestionnaires')
      } catch (error) {
        commit('PUSH_ERROR', { head: 'Загрузка файлов', error })
        throw error
      } finally {
        commit('UPDATING', false)
      }
    },
  },
}
