import Vue from 'vue'
import Vuex from 'vuex'

import user from '@/store/user'
import cr from '@/store/clinRecs'
import questionnaire from '@/store/questionnaire'
import login from '@/store/login'
import adminListFilter from '@/store/adminListFilter'
import files from '@/store/files'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    user,
    cr,
    questionnaire,
    login,
    adminListFilter,
    files,
  },
  state: {
    // флаг загрузки
    loadig: 0,
    // флаг отправка
    updating: 0,
    // пулл ошибок
    errors: [],
  },
  mutations: {
    // добавляем ошибку в пулл
    PUSH_ERROR(state, { head, error, err, e }) {
      let errObj = error || err || e
      state.errors.push(errObj)
      // шапка к ошибке
      let errHead = `[Ошибка]${head ? `[${head}]` : ''}`
      console.error(errHead, errObj)
      // покажем ошибку красиво
      Vue.$toast.error(`${errHead} ${errObj}`, {
        timeout: 5000,
        icon: true,
      })
    },
    // чистим пулл ошибок
    POP_ERROR(state) {
      state.errors.pop()
    },
    LOADING(state, loading) {
      state.loadig = state.loadig + loading ? 1 : -1
    },
    UPDATING(state, updating) {
      state.updating = state.updating + updating ? 1 : -1
    },
  },
  actions: {
    // очищаем последнюю ошибку
    CLEAR_LAST_ERROR: ({ commit }) => {
      commit('POP_ERROR')
    },
  },
  getters: {
    isBusy: state => state.loadig || state.updating,
    getError: state =>
      state.errors.length ? state.errors[state.errors.length - 1] : '',
  },
})
